<script setup lang="ts">
import { useDisplay } from "vuetify";

const emit = defineEmits(["close"]);
const { mdAndUp } = useDisplay();

const config = useRuntimeConfig();
const blogLink = config.public.blogLink;
</script>

<template>
  <div class="megamenu-inner megamenu-margin">
    <div v-if="!mdAndUp" class="ma-3 d-flex justify-end">
      <div class="d-flex align-center" @click="emit('close')">
        <VIcon icon="custom-back" />
      </div>
    </div>
    <VRow>
      <VCol cols="12" md="4" class="megamenu-block text-center text-md-left">
        <div>
          <h4 class="mb-1">
            {{ $t("navbar.community_first_title") }}
          </h4>
          <div class="megamenu-block-list-item">
            <NuxtLink class="list-link" to="/">
              {{ $t("navbar.community_hub") }} <span class="ml-2 list-link--comming-soon">Coming Soon</span>
            </NuxtLink>
          </div>

          <div class="megamenu-block-list-item">
            <NuxtLink class="list-link" :to="blogLink" target="_blank">
              {{ $t("navbar.blog") }} 
            </NuxtLink>
          </div>
          <div class="megamenu-block-list-item">
            <NuxtLink class="list-link" to="/">
              {{ $t("navbar.press") }} <span class="ml-2 list-link--comming-soon">Coming Soon</span>
            </NuxtLink>
          </div>
        </div>
      </VCol>
    </VRow>
  </div>
</template>
